import React from 'react';

function Home() {
    return (
        <div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }}>
            <h1>Home</h1>
            <span>A q p</span>
            <span>asdfasdf</span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;W</span>
        </div>
    );
}

export default Home;
